@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max(100vh, 100%);
  min-height: 100vh;
  background-color: #eef0ea;

  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.main.mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.illustration {
  position: relative;
  background-image: url('../../assets/spotview-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eef0ea;
}

.illustration .gradient {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;

  background: #eef0ea;
  background: linear-gradient(75deg, #eef0ea 23%, rgba(0, 255, 74, 0) 35%);
}

.illustration .preview {
  position: absolute;
  bottom: 100px;
  right: -25%;
  display: block;
  width: calc(100% - 100px);
  height: 450px;
  background-image: url('../../assets/spotview-preview.png');
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  animation: slideIn 1.5s ease-in-out forwards;
}

.content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25% 1fr;
  margin: 0 auto;
  gap: 16px;
  padding: 16px;
}

.content .logo {
  margin: auto;
  min-width: 180px;
  width: 100%;
  max-width: 250px;
}

.content .form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: min(100%, 350px);
  height: min-content;
  padding: 24px;
  border-radius: 4px;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  margin: 0 auto;
}

.content .form fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content .form .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}

.content .form fieldset > input[data-valid='false'] {
  border: 1px solid red !important;
}

.content .form .heading {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.content .form .heading p {
  font-size: 16px;
  line-height: 150%;
}

.error {
  font-size: 12px;
  color: red;
  line-height: 100%;
}

@keyframes slideIn {
  0% {
    right: -125%;
  }
  100% {
    right: -25%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (width < 450px) {
  .content .logo {
    width: 80%;
    max-width: 200px;
  }
}
