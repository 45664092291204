@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  width: min(calc(100% - 24px), 450px);
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  animation: fadeOut 1s ease forwards;
}

.divIconClose {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.iconClose {
  width: 20px;
  cursor: pointer;
}

.title {
  margin-bottom: 8px;
  font-size: clamp(24px, 2vw, 32px);
}

.divTitle {
  text-align: center;
}

.iconLock {
  width: 20%;
  margin-bottom: 16px;
}

.paragraph {
  font-size: clamp(14px, 2vw, 16px);
  margin-bottom: 16px;
}

.button {
  margin-top: 16px;
  width: 100px;
  height: 40px;
}

.divButton {
  display: flex;
  justify-content: end;
  margin-left: 0;
  gap: 8px;
}
