* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

hr {
  border: none;
  height: 1px;
  background-color: lightgray;
}
