.button {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #72be44;
  color: white;
  transition: filter 0.1s linear;
}

.button:hover:not(:disabled) {
  filter: brightness(0.9);
}

.button:active:not(:disabled) {
  filter: brightness(0.7);
}

.button:disabled {
  background-color: lightgray;
  cursor: auto;
}
