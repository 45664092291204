@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  width: min(calc(100% - 24px), 450px);
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  animation: fadeIn 1s ease forwards;
}

.divIconClose {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.iconClose {
  width: 20px;
  cursor: pointer;
}

.divTitle {
  text-align: center;
  margin-bottom: 12px;
}

.iconLock {
  width: 15%;
  margin-bottom: 8px;
}

.title {
  margin-bottom: 8px;
  font-size: clamp(24px, 2vw, 32px);
}

.paragraph {
  font-size: clamp(14px, 2vw, 16px);
}

.inputEmail {
  flex: 1;
  width: 100%;
}

.error {
  font-size: 12px;
  color: red;
  line-height: 100%;
}

.button {
  background-color: white;
  color: #67ab3d;
  border: 1px solid #67ab3d;
}

.divButton {
  display: flex;
  justify-content: end;
  gap: 8px;
}
